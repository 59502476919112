<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col
      md="12"
      cols="12"
    >
      <v-card class="py-2 elevation-0">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="users.user.avatar ? '' : 'primary'"
            :class="users.user.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            class="mb-4"
          >
            <v-img
              v-if="userDetail.image"
              :src="userDetail.image"
            ></v-img>
            <v-img
              v-else
              src="@/assets/images/avatars/ari.png"
            ></v-img>
            <!-- <span
              class="font-weight-semibold text-5xl"
            >{{ avatarText(users.user.name) }}</span> -->
          </v-avatar>

          <span class="mb-2">{{ users.user.name }}</span>
          <div class="d-flex">
            <v-chip
              label
              small
              :color="resolveUserRoleVariant(users.user.role)"
              :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(users.user.role)}--text text-capitalize mr-2`"
            >
              {{ users.user.role }}
            </v-chip>
            <v-chip
              small
              label
              :color="resolveUserStatusVariant(users.user.status)"
              :class="`v-chip-light-bg ${resolveUserStatusVariant(users.user.status)}--text font-weight-medium text-capitalize ml-2`"
            >
              {{ users.user.status }}
            </v-chip>
          </div>
        </v-card-title>

        <!-- <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Name:</span>
              <span class="text--secondary">{{ userDetail.name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ users.user.email }}</span>
              <span
                v-if="users.user.email === null"
                class="text--secondary"
              >-</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(users.user.status)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(users.user.status)}--text font-weight-medium text-capitalize`"
                >
                  {{ users.user.status }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Role:</span>
              <span class="text--secondary text-capitalize">{{ users.user.role }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card> -->

        <!-- edit profile dialog data -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api'
import useUsersList from '../../user-list/useUsersList'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const { resolveUserStatusVariant, resolveUserRoleVariant } = useUsersList()

    const isBioDialogOpen = ref(false)
    const users = JSON.parse(localStorage.getItem('user'))
    const userDetail = JSON.parse(localStorage.getItem('user')).user.details[0]
    const user = ref({})

    return {
      resolveUserStatusVariant,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,
      users,
      user,
      userDetail,
      isBioDialogOpen,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
