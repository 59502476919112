<template>
  <v-card class="user-tab-notification">
    <notifactions></notifactions>
  </v-card>
</template>

<script>
import Notifactions from '../../../notifications/Notification.vue'

export default {
  components: {
    Notifactions,
  },
  setup() {
    const tableColumn = [
      {
        text: 'TYPE',
        value: 'type',
        sortable: false,
      },
      {
        text: 'EMAIL',
        value: 'email',
        sortable: false,
        align: 'center',
      },
      {
        text: 'BROWSER',
        value: 'browser',
        sortable: false,
        align: 'center',
      },
      {
        text: 'APP',
        value: 'app',
        sortable: false,
        align: 'center',
      },
    ]
    const notifications = [
      {
        type: 'New for you',
        email: true,
        browser: false,
        app: false,
      },
      {
        type: 'Account activity',
        email: false,
        browser: true,
        app: true,
      },
      {
        type: 'A new browser used to sign in',
        email: true,
        browser: true,
        app: true,
      },
      {
        type: 'A new device is linked',
        email: false,
        browser: true,
        app: false,
      },
    ]

    return {
      tableColumn,
      notifications,
    }
  },
}
</script>
