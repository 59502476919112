<template>
  <v-card>
    <div class="theme-color">
      <v-card-title>
        <v-icon
          size="26"
          class="text--primary me-3"
        >
          {{ icons.mdiChartTimelineVariant }}
        </v-icon>
        <span>Tema Warna</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-menu
            nudge-right="50"
            nudge-top="8"
            class="theme-color"
          >
            <!-- Activator -->
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class=""
                style="width: 40px"
                v-on="on"
              >
                <div
                  :class="classSelectedThemeColor"
                >
                </div>
                <span></span>
              </div>
            </template>

            <v-list>
              <v-list-item-group
                class="d-flex"
              >
                <v-list-item
                  v-for="color in colorThemes"
                  :key="color.background"
                  class=""
                  style="padding: 0 8px"
                >
                  <div class="d-flex">
                    <div

                      :class="'btn-theme-child '+color.class"
                    >
                    </div>
                  </div>
                <!-- <v-list-item-title class="ml-2">{{ color.title }}</v-list-item-title> -->
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
        <!-- <div class="d-flex" >
            <div class="mr-4" v-for="color in colorThemes" :key="color.title">
              <div class="mb-3">{{ color.title }}</div>
              <div :class="color.class"></div>
            </div>
        </div> -->
      </v-card-text>
    </div>
    <div class="theme-languange">
      <v-card-title>
        <v-icon
          size="26"
          class="text--primary me-3"
        >
          {{ icons.mdiChartTimelineVariant }}
        </v-icon>
        <span>Bahasa</span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-menu
            nudge-right="50"
            nudge-top="8"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="theme-flag"
                style="width: 40px"
                v-on="on"
              >
                <v-img
                  :src="getImgUrl()"
                  :alt="$i18n.locale"
                ></v-img>
                <!-- <v-img
                  v-if="school.locale === 'id'"
                  :src="require('@/assets/images/flags/id.png')"
                  :alt="$i18n.locale"
                ></v-img>
                <v-img
                  v-else
                  :src="require('@/assets/images/flags/en.png')"
                  :alt="$i18n.locale"
                ></v-img> -->
              </div>
            </template>
            <v-list>
              <v-list-item-group
                :value="$i18n.locale"
                class="d-flex"
              >
                <v-list-item
                  v-for="locale in locales"
                  :key="locale.locale"
                  :value="locale.locale"
                  style="padding: 0 8px"
                  @change="updateActiveLocale(school.uuid, locale.locale)"
                >
                  <v-img
                    :src="locale.img"
                    height="40px"
                    width="40px"
                    :alt="locale.locale"
                  ></v-img>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { mdiChartTimelineVariant, mdiEmailOutline } from '@mdi/js'
import { loadLanguageAsync } from '@/plugins/i18n'
import { ref, onMounted, watch } from '@vue/composition-api'
// import messages from '@/plugins/i18n/locales/id'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

export default {
  setup(props, context) {
    const colorThemes = [
      { title: 'Default', background: '#EA008C', class: 'colorThemeDefault' },
      { title: 'Bluesky', background: '#005EDD', class: 'colorThemeBluesky' },
    ]
    const locales = ref([
      { title: 'English', img: require('@/assets/images/flags/en.png'), locale: 'en' },
      { title: 'Indonesia', img: require('@/assets/images/flags/id.png'), locale: 'id' },
    ])

    const school = ref({
      uuid: JSON.parse(localStorage.getItem('user')).user.details[0].uuid,
      locale: '',
    })
    const locale = ref('')
    const formData = ref('')
    const classSelectedThemeColor = 'bg-theme-pink btn-theme-parent'
    const classSelectedThemeLanguange = ''

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const getLocale = async uuid => {
      uuid = school.value.uuid
      await context.root.$services.ApiServices.get('school', uuid).then(({ data }) => {
        school.value = data.data
      })
    }

    const getImgUrl = () => {
      if (school.value.locale === 'id') {
        return require('@/assets/images/flags/id.png')
      }
      if (school.value.locale === 'en') {
        return require('@/assets/images/flags/en.png')
      }

      return require('@/assets/images/flags/id.png')
    }

    const updateActiveLocale = async (uuid, locale) => {
      school.value.uuid = uuid
      locales.value.locale = locale
      formData.value = new FormData()
      formData.value.append('locale', locale)
      await context.root.$services.ApiServices.update('school', formData.value, uuid).then(
        ({ data }) => {
          showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          loadLanguageAsync(locale)
          getLocale()
        },
        err => console.error(err),
      )
    }

    // const updateActiveLocale = async (uuid, locale) => {
    //   user.value.role = JSON.parse(localStorage.getItem('user')).user.role
    //   if (user.value.role === 'school') {
    //     role.value = user.value.role
    //     school.value.uuid = uuid
    //     locales.value.locale = locale
    //     formData.value = new FormData()
    //     formData.value.append('locale', locale)
    //     await context.root.$services.ApiServices.update('school', formData.value, uuid).then(
    //       ({ data }) => {
    //         showSnackbar({
    //           text: data.result.message,
    //           color: 'success',
    //         })
    //       },
    //       err => console.error(err),
    //     )
    //   } else if (user.value.role === 'teacher' || user.value.role === 'headmaster') {
    //     this.role = this.user.role
    //   }
    // }

    // const getRole = async () => {
    //   user.value.role = JSON.parse(localStorage.getItem('user')).user.role
    //   // if (user.value.role !== 'school' && user.value.role !== 'teacher' && user.value.role !== 'headmaster') {
    //   //   role.value = user.value.role
    //   //   await getUser()
    //   if (user.value.role === 'school') {
    //     role.value = user.value.role
    //     await updateActiveLocale(context.root.$route.params.uuid)
    //   } else if (user.value.role === 'teacher' || user.value.role === 'headmaster') {
    //     this.role = this.user.role
    //     await updateActiveLocale(context.root.$route.params.uuid)
    //   }
    // }

    onMounted(() => {
      getLocale()
    })

    return {
      locales,
      locale,
      school,
      getLocale,
      getImgUrl,
      updateActiveLocale,
      colorThemes,
      classSelectedThemeColor,
      classSelectedThemeLanguange,
      formData,
      icons: {
        mdiChartTimelineVariant,
        mdiEmailOutline,
      },
    }
  },
}
</script>

<style>
</style>
