<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title>
        Ganti Password
      </v-card-title>

      <v-card-text>
        <v-alert
          color="warning"
          text
        >
          <p class="font-weight-semibold mb-1">
            Pastikan persyaratan ini terpenuhi
          </p>
          <p class="text-sm mb-0">
            Panjang minimal 8 karakter, huruf besar &amp; simbol
          </p>
        </v-alert>

        <!-- form -->
        <v-form class="multi-col-validation">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                label="New Password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                label="Confirm New Password"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="mt-6 pa-0">
          <v-btn
            color="primary"
            :loading="isLoadingButton"
            @click="updatePassword(userUuid)"
          >
            Ganti Password
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'

export default {
  setup(props, context) {
    const istwoFactorDialogOpen = ref(false)
    const service = ref('user')
    const newPassword = ref('')
    const confirmPassword = ref('')
    // const password = ref({
    //   password: '',
    //   confirm_password: '',
    // })
    const userUuid = ref('')
    const user = ref([])
    const isLoadingButton = ref(false)
    const smsVerificationNumber = ref('+1(968) 819-2547')
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const resetForm = () => {
      newPassword.value = ''
      confirmPassword.value = ''
    }
    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }
    const getUuid = () => {
      user.value = JSON.parse(localStorage.getItem('user')).user
      userUuid.value = user.value.uuid
    }
    const updatePassword = async uuid => {
      userUuid.value = uuid
      isLoadingButton.value = true
      const formData = new FormData()
      formData.append('password', newPassword.value)
      formData.append('confirm_password', confirmPassword.value)
      await context.root.$services.ApiServices.update(service.value, formData, uuid).then(
        ({ data }) => {
          showSnackbar({
            text: 'Update password has successfully',
            color: 'success',
          })
        },
        err => {
          isLoadingButton.value = false
          console.error(err)
        },
      )
      isLoadingButton.value = false
      resetForm()
    }
    onMounted(() => {
      getUuid()
    })

    return {
      istwoFactorDialogOpen,
      newPassword,
      confirmPassword,
      isLoadingButton,
      // password,
      smsVerificationNumber,
      isPasswordVisible,
      isPasswordConfirmVisible,
      updatePassword,
      resetForm,
      service,
      userUuid,
      user,
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      getUuid,
    }
  },
}
</script>
